<template>
  <div>
    <top-bar :title="'我的'" :bgc="'#387FF5'" :color="'#fff'"></top-bar>
    <nav-bar :id="3"></nav-bar>
    <div class="banner">
      <img style="width:100%" :src="bannerImg" />
      <div class="title">我的</div>
    </div>
    <div class="user-info">
      <div class="user-top">
        <img :src="myinfo.headImg || defaultHeader" class="header" />
        <div class="center">
          <div class="name">{{myinfo.userName || '未知'}}
            <div class="login-time" v-if="myinfo.loginTime">上次登录：{{ myinfo.loginTime }}</div>
          </div>
        </div>
        <img src="@/assets/img/code.png" class="code" @click="getQRCode" />

      </div>

        <div class="user-content">
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-name.png" />
            <div style="margin-left: 12px;color: #666">姓名</div>
            <div style="flex: 1;text-align: right">{{ myinfo.userName }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-mobile.png" />
            <div style="margin-left: 12px;color: #666">手机号码</div>
            <div style="flex: 1; text-align: right; color: #387FF5" @click="$router.push('./street-mobile-change')">{{ myinfo.mobile }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-org.png" />
            <div style="margin-left: 12px;color: #666">所属机构</div>
            <div style="flex: 1;text-align: right">{{ myinfo.orgName }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-post.png" />
            <div style="margin-left: 12px;color: #666">职务</div>
            <div style="flex: 1;text-align: right">{{ myinfo.userPostName }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-type.png" />
            <div style="margin-left: 12px;color: #666">人员类型</div>
            <div style="flex: 1;text-align: right">{{ myinfo.typeName }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-charges.png" />
            <div style="margin-left: 12px;color: #666">负责业务</div>
            <div style="flex: 1;text-align: right">{{ myinfo.chargesName }}</div>
          </div>
          <div class="content-item" style="border: none">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-password.png" />
            <div style="margin-left: 12px;color: #666">密码</div>
            <div style="flex: 1;text-align: right; color:#387FF5" @click="$router.push({path: './street-pwd-change', query: {mobile: mobile}})">修改密码</div>
          </div>
        </div>
        <div class="unlink-btn" @click="quit">解绑账号</div>

    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import navBar from '@/components/navBar/navBar'

import { getImageStream } from '@/utils/index'
import { desensitization } from '@/utils/utils'
import Vue from 'vue'
export default {
  data() {
    return {
      bannerImg: getImageStream('files/wx/images/content/my-banner.png',process.env.VUE_APP_BASE_BACK_URL),
      defaultHeader: getImageStream('files/wx/images/content/header-default.png',process.env.VUE_APP_BASE_BACK_URL),
      myinfo: {
        headImg: '',
        userName: '',
        mobile: '',
        orgName: '',
        userPostName: '',
        typeName: '',
        chargesName: '',
        loginTime: ''
      },
      mobile: ''
    }
  },
  components: {
    topBar,
    navBar
  },
  methods: {
    getinfo() {
      this.$router.push('/my-info')
    },
    getQRCode() {
      this.$router.push('/street-my-qrcode')
    },
    quit() {
      this.$dialog.confirm({
        title: '退出登录',
        message: '该操作将解绑账号,确定吗?',
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/unlink'),
          method: 'post',
          params: this.$http.adornParams({
            id: this.$globalData.userInfo.userId
          }, false)
        }).then(({ data }) => {
          if (data.code == 0) {
            this.$globalData.token = ''
            this.$globalData.topToken = ''
            this.$globalData.backToken = ''
            this.$globalData.areaToken = ''
            this.$router.replace('/white')
          }
        })
      })
    },
    getjmgginfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/info'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$globalData.userInfo.orgId,
          id: this.$globalData.userInfo.userId
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          let userManager = data.userManager
          this.myinfo.headImg = userManager.headImg? getImageStream(userManager.headImg) : this.defaultHeader
          this.myinfo.userName = userManager.name || app.globalData.userInfo.communityName + '社工'
          this.myinfo.mobile = desensitization(userManager.mobile) || ''
          this.mobile = userManager.mobile || ''
          this.myinfo.orgName = userManager.streetName
          this.myinfo.userPostName = userManager.postName || '无'
          this.myinfo.typeName = userManager.typeName || ''
          this.myinfo.chargesName = userManager.chargesName || '无'
          this.myinfo.loginTime = userManager.loginTime
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    changeCommunity() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/list/community'),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: '',
          userId: this.$globalData.userInfo.userId
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          if (data.list[1]) {
            Vue.prototype.$orgList = data.list
            this.$router.push('/community-change')
          } else {
            this.$toast.fail('没有获取到你在其他社区的信息');
          }
        } else {
          this.$toast.fail(data.msg);
        }
      })
    }
  },
  created() {
    // document.querySelector('html').style.backgroundColor = '#387FF5'
    // document.querySelector('body').style.backgroundColor = '#387FF5'
    this.getjmgginfo()
  },
  beforeDestroy() {
    // document.querySelector('html').style.backgroundColor = ''
    // document.querySelector('body').style.backgroundColor = ''
  }
}
</script>

<style lang="scss" scoped>
/* pages/my/my.wxss */
.banner {
  position: relative;
  height: 396px;
}

.title {
  font-size: 28px;
  position: absolute;
  top: 108px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
}

.user-info {
  padding-top: 24px;
  min-height: calc(100vh - 386px);
  position: absolute;
  top: 366px;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  background: #fff;
}

.user-top {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 690px;
  height: 190px;
  box-shadow: 0px 2px 12px 0px rgba(102, 102, 102, 0.1);
  border-radius: 10px;
  padding: 40px 34px 40px 24px;
  box-sizing: border-box;
}

.header {
  width: 100px;
  height: 100px;
}

.user-top .center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 24px;
  flex: 1;
  padding: 5px 0;
  box-sizing: border-box;
}

.user-top .name {
  font-size: 32px;
  line-height: 54px;
  color: #333;
}

.user-top .login-time {
  font-size: 24px;
  line-height: 40px;
  color: #999;
}

.code {
  width: 36px;
  height: 36px;
}

.user-content {
  margin: 24px 30px 0;
  width: 690px;
}

.content-item {
  display: flex;
  align-items: center;
  height: 88px;
  border-bottom: 1px solid #EDEDED;
  font-size: 28px;
  margin-bottom: 14px;
}

.unlink-btn {
  font-size: 28px;
  margin: 40px 30px 0;
  width: 690px;
  height: 88px;
  line-height: 88px;
  opacity: 0.6;
  background: #387FF5;
  border-radius: 10px;
  text-align: center;
  color: #fff;
}
</style>
